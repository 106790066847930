/* eslint-disable */
import EventEmitter from 'events';

export const events = new EventEmitter();

var fragFilename = 'shaders/fragbasic.frag';
var colorFilename = 'shaders/fragcolor.frag';

var fragshader = '';
var colorshader = '';
var filesLoading = 0;

// threejs scene setup - this is the scene that is displayed
var screenScene;
var camera;
var renderer;

var bufferScene; // This is the scene with the webGL shader
var outputTexture;
var last1Texture;
var last2Texture;
var bufferMaterial;
var plane;

var cube;

var screenMaterial;
var screenMesh;

var renderCanvas;

var frameCount = 0.0;
var mixRatio = -1.0;
var frameSpeed = 2;
var rotationCount = 0.0;
var stepCount = 0.0;
var stepSpeed = 2;
var w = Math.PI / 2.0;
var direction = 1;

var valueMultiplier = 2;

var sides = 4;
var targetColor = '000000'; // Hex Str of HSV that was passed

const routines = {
  darkstar: 'c-1;p-2;z-2;w-9000',
  descendent: 'c-3;p-2;z-2;w-9000',
  primordial: 'c-3;p-2;z-2;w-9000',
  transition: 'c-4;r-1;g-1;p-9;z-2;w-9000',
  dead: 'c-1;p-2;z-2;w-9000',
};

// Colors are HSB (same as HSV)  0-360/0-1/0-1
var colors = [
  [204, 0.77, 0.58],
  [204, 0.55, 0.72],
  [204, 0.52, 0.82],
  [204, 0.46, 0.88],
  [159, 0.19, 0.57],
  [159, 0.31, 0.61],
  [133, 0.31, 0.61],
  [91, 0.72, 0.61],
  [77, 0.71, 0.56],
  [65, 0.73, 0.62],
  [59, 0.79, 0.83],
  [59, 0.8, 0.9],
  [59, 0.51, 1.0],
  [54, 0.45, 1.0],
  [54, 0.24, 1.0],
  [35, 0.24, 1.0],
  [35, 0.49, 0.91],
  [35, 0.69, 0.89],
  [35, 0.79, 0.96],
  [24, 0.8, 0.91],
  [16, 0.86, 0.91],
  [16, 0.91, 0.8],
  [16, 0.96, 0.62],
  [16, 0.79, 0.43],
];

var showBorder = 0;
var borderGap = 0;
var colorMode = 3;
var colorModeCount = 9;
var rotationSpeed = 0;

var width;
var height;

class ScriptCommand {
  constructor(aStr) {
    var parts = aStr.split('-', 2);
    this.command = parts[0].toLowerCase();
    if (parts.length > 1) {
      this.param = parseInt(parts[1]);
    } else {
      this.param = null;
    }
  }

  out() {
    if (this.param != null) {
      return this.command + '(' + this.param + ')<br>';
    } else {
      return this.command + '<br>';
    }
  }
}

class CommandRunner {
  commandList = [];
  numCommands = 0;
  currentCommand = 0;
  waituntil = 0;
  fadeuntil = 0;

  alter(source) {
    const commandList = [];

    if (source && source.length) {
      let commandStrings = source.split(';');
      commandStrings.forEach((element) => commandList.push(new ScriptCommand(element)));
      commandList.push(new ScriptCommand('w-15000'));
      commandList.push(new ScriptCommand('t'));

      events.emit('reset');

      this.commandList = commandList;
      this.numCommands = this.commandList.length;
      this.currentCommand = 0;
      this.waituntil = 0;
      this.fadeuntil = 0;
    }
  }

  go() {
    let done = false;
    let restart = false;
    let aCommand;
    if (this.currentCommand >= this.numCommands) {
      return;
    }

    if (this.fadeuntil > frameCount) {
      valueMultiplier = 1 - 5 / (this.fadeuntil - frameCount);
      if (valueMultiplier < 0) {
        valueMultiplier = 0;
      }
      return;
    }
    valueMultiplier = 1;
    if (this.waituntil > frameCount) {
      return;
    }
    this.waituntil = 0;
    let debugStr = '';
    while (!done) {
      aCommand = this.commandList[this.currentCommand];
      debugStr = debugStr + '[' + this.currentCommand + ']' + aCommand.out() + '<br>';
      if (aCommand.command === 'w') {
        this.waituntil = frameCount + parseInt(aCommand.param);
        done = true;
      } else if (aCommand.command === 's') {
        sides = parseInt(aCommand.param);
      } else if (aCommand.command === 't') {
        restart = true;
        done = true;
        colorMode = 0;
      } else if (aCommand.command === 'b') {
        showBorder = parseInt(aCommand.param);
      } else if (aCommand.command === 'r') {
        rotationSpeed = parseInt(aCommand.param);
      } else if (aCommand.command === 'g') {
        borderGap = parseInt(aCommand.param);
      } else if (aCommand.command === 'c') {
        colorMode = parseInt(aCommand.param);
        if (colorMode > colorModeCount) {
          colorMode = 0;
        }
        mixRatio = -1;
      } else if (aCommand.command === 'h') {
        targetColor = aCommand.param.toString(16);
        targetColor = targetColor.padStart(6, '0');
      } else if (aCommand.command === 'p') {
        stepSpeed = aCommand.param;
        if (stepSpeed > 9) {
          stepSpeed = 6;
        }
      } else if (aCommand.command === 'f') {
        this.fadeuntil = frameCount + parseInt(aCommand.param);
        done = true;
      } else if (aCommand.command === 'z') {
        frameSpeed = aCommand.param;
        if (frameSpeed > 9) {
          frameSpeed = 9;
        }
      }
      this.currentCommand++;
      if (this.currentCommand >= this.numCommands) {
        done = true;
      }
    }

    if (restart) {
      this.currentCommand = 0;
      events.emit('reset');
    }
  }
}

function getTargetColor() {
  var result;
  if (targetColor != '000000') {
    // targetColor is an HSV Hex Str
    result = new THREE.Vector3(
      parseInt(targetColor.substr(0, 2), 16) / 256,
      parseInt(targetColor.substr(2, 2), 16) / 256,
      parseInt(targetColor.substr(4, 2), 16) / 256
    );
  } else {
    result = new THREE.Vector3(colors[sides - 3][0] / 360.0, colors[sides - 3][1], colors[sides - 3][2]);
  }
  return result;
}

function updateCounters() {
  frameCount++;
  mixRatio = mixRatio + 0.0005;
  mixRatio = Math.min(1.02, mixRatio);

  if (rotationSpeed > 0) {
    rotationCount++;
  }

  w += Math.PI / (525 + stepCount);
  const speedOptions = [0, 0.005, 0.01, 0.02, 0.05, 0.08, 0.1, 0.15, 0.2, 0.3];
  stepCount += speedOptions[stepSpeed] * direction;
  if (stepCount > 50) {
    direction = -1;
  } else if (stepCount < -50) {
    direction = 1;
  }
}

function sceneSetup() {
  screenScene = new THREE.Scene();
  screenScene.background = new THREE.Color(0x000000);

  camera = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 1000);
  camera.position.z = 1.0;

  renderCanvas = document.getElementById('webgl-display');
  renderer = new THREE.WebGLRenderer({ canvas: renderCanvas });
  renderer.setSize(width, height);
}

function setBufferUniforms() {
  bufferMaterial.uniforms.u_rotatecount.value = rotationCount;
  bufferMaterial.uniforms.u_framecount.value = frameCount;
  bufferMaterial.uniforms.u_stepcount.value = stepCount;
  bufferMaterial.uniforms.u_w.value = w;
  bufferMaterial.uniforms.u_bufferTexture1.value = last1Texture.texture;
  bufferMaterial.uniforms.u_bufferTexture2.value = last2Texture.texture;
  bufferMaterial.uniforms.u_sides.value = sides;
  bufferMaterial.uniforms.u_showborder.value = showBorder;
  bufferMaterial.uniforms.u_bordergap.value = borderGap;
  bufferMaterial.uniforms.u_rotationspeed.value = rotationSpeed;
  bufferMaterial.uniforms.u_colormode.value = colorMode;
  bufferMaterial.uniforms.u_multiplier.value = valueMultiplier;
  bufferMaterial.uniforms.needsUpdate = true;
}

function setScreenUniforms() {
  screenMaterial.uniforms.u_mixratio.value = mixRatio;
  screenMaterial.uniforms.u_bufferTexture.value = outputTexture.texture;
  screenMaterial.uniforms.u_colormode.value = colorMode;
  screenMaterial.uniforms.u_stepcount.value = stepCount;
  screenMaterial.uniforms.u_targetColor.value = getTargetColor();
  screenMaterial.uniforms.u_paletteSize.value = sides;

  screenMaterial.uniforms.needsUpdate = true;
}

function bufferTextureSetup() {
  //Create buffer scene, this is the scene that will be fed into our shader
  bufferScene = new THREE.Scene();

  bufferScene.background = null;

  //Create 3 buffer textures
  outputTexture = new THREE.WebGLRenderTarget(width, height, {
    type: THREE.HalfFloatType,
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
  });
  last1Texture = new THREE.WebGLRenderTarget(width, height, {
    type: THREE.HalfFloatType,
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
  });
  last2Texture = new THREE.WebGLRenderTarget(width, height, {
    type: THREE.HalfFloatType,
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
  });

  bufferMaterial = new THREE.ShaderMaterial({
    uniforms: {
      u_rotatecount: { value: rotationCount },
      u_framecount: { value: frameCount },
      u_bufferTexture1: { type: 't', value: last1Texture.texture },
      u_bufferTexture2: { type: 't', value: last2Texture.texture },
      u_res: { type: 'v2', value: new THREE.Vector2(width, height) },
      u_w: { value: w },
      u_stepcount: { value: stepCount },
      u_sides: { value: sides },
      u_showborder: { value: showBorder },
      u_bordergap: { value: borderGap },
      u_rotationspeed: { value: rotationSpeed },
      u_colormode: { value: colorMode },
      u_multiplier: { value: valueMultiplier },
    },
    fragmentShader: fragshader,
  });

  plane = new THREE.PlaneGeometry(width, height);
  cube = new THREE.BoxGeometry(200, 200, 20);

  bufferScene.add(new THREE.Mesh(plane, bufferMaterial));

  //Draw to screen

  screenMaterial = new THREE.ShaderMaterial({
    uniforms: {
      u_mixratio: { value: mixRatio },
      u_stepcount: { value: stepCount },
      u_bufferTexture: { type: 't', value: outputTexture.texture },
      u_res: { type: 'v2', value: new THREE.Vector2(width, height) },
      u_colormode: { value: colorMode },
      u_targetColor: { value: getTargetColor() },
      u_paletteSize: { value: sides },
    },
    fragmentShader: colorshader,
  });
  screenMesh = new THREE.Mesh(cube, screenMaterial);
  screenMesh = new THREE.Mesh(plane, screenMaterial);

  screenScene.add(screenMesh);
}

function render() {
  setBufferUniforms();

  // Draw to outputTexture using bufferScene which has a webGL material
  //  - We will remember this texture for the future
  //  - We will draw this texture out to the screen

  renderer.render(bufferScene, camera, outputTexture, true);
  renderer.setClearColor(0xeeeeee);

  //Update framecount, stepcount, direction and w
  updateCounters();

  setScreenUniforms();

  //Finally, draw to the screen
  renderer.render(screenScene, camera);

  //Rotate textures
  if (frameSpeed != 0 && (frameCount < 10 || frameCount % frameSpeed == 0)) {
    var t = last2Texture;
    last2Texture = last1Texture;
    last1Texture = outputTexture;
    outputTexture = t;
  }

  commands.go();

  requestAnimationFrame(render);
}

function ready() {
  // Content of our program is here!!!
  frameCount = 0;

  // Initialize the Threejs scene
  sceneSetup();

  // Setup the frame buffer/texture we're going to be rendering to instead of the screen
  bufferTextureSetup();

  // Animate
  render();
}

function loaded() {
  // After each file is loaded this routine is called to see if
  //   all files are loaded. If yes, we call the ready function.
  filesLoading--;
  if (filesLoading < 1) {
    ready();
  }
}

function myLoadGLSL() {
  // loading a fragment glsl file
  filesLoading++;
  fetch(fragFilename)
    .then((response) => response.text())
    .then((data) => {
      fragshader = data;
      loaded();
    });
  filesLoading++;
  fetch(colorFilename)
    .then((response) => response.text())
    .then((data) => {
      colorshader = data;
      loaded();
    });
}

var commands = new CommandRunner();

events.on('init', (parent) => {
  height = parent.clientHeight;
  width = height * 2;

  myLoadGLSL();
});



events.on('resize', (parent) => {
  const desireRatio = 0.5
  const maxRation = parent.clientHeight / parent.clientWidth

  if (maxRation < 1 && maxRation > desireRatio) {
    if (parent.clientWidth > parent.clientHeight) {
      width = parent.clientWidth;
      height = parent.clientWidth * 0.5;
    } else {
      height = parent.clientHeight;
      width = parent.clientHeight * 0.5;
    }
  } else {
    if (parent.clientHeight > parent.clientWidth) {
      width = parent.clientWidth;
      height = parent.clientWidth * 0.5;
    } else {
      height = parent.clientHeight
      width = parent.clientHeight * 2;
    }
  }

  ready()
})

events.on('reset', () => {
  frameCount = 0;
  stepCount = 0.0;
  w = Math.PI / 2.0;
  direction = 1;

  rotationSpeed = 0;
  showBorder = 0;
  borderGap = 0;

  mixRatio = -1.0;
  frameSpeed = 1;
  rotationCount = 0.0;
  stepSpeed = 6;
  targetColor = '000000';
});

events.on('state', (key, options) => {
  let query = routines[key];

  if (!query) {
    throw new Error('Invalid token state');
  } else {
    query = `s-${options.sides};${query}`;

    commands.alter(query);
  }
});

window.ee = events;
