import React from 'react';

import { Box, Grid, GridItem } from '@chakra-ui/react';

import bgImage from '../assets/ql_background.png';

import Footer from './Footer';
import Header from './Header';
import { LayoutProps } from './Layout.types';
import Sidebar from './Sidebar';

export default function Layout({ children }: LayoutProps) {
  return (
    <Box
      h="100vh"
      w="100vw"
      maxH="100vh"
      maxW="100vw"
      d="flex"
      background={`linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${bgImage})`}>
      <Grid
        maxH="100vh"
        maxW="100vw"
        templateColumns={{ base: '0fr 1fr', lg: '210px minmax(0, 4fr) 0fr' }}
        templateRows="0fr minmax(0, 1fr) 0fr"
        gridTemplateAreas={{
          base: `"header-leftbar header" "body body" ". footer"`,
          lg: `"header-leftbar header rightbar-header" "leftbar body rightbar" ". footer footer"`,
        }}
        my="37"
        width="100%">
        <Header />
        <Sidebar />
        <GridItem gridArea="body" px="6" pt="6" maxH="100%" maxW="100%">
          {children}
        </GridItem>
        <Footer />
      </Grid>
    </Box>
  );
}
