import { NFTType } from 'state/nfts.types';

export function getNftType(isDarkstar: boolean, isDescendent: boolean): NFTType {
  if (isDarkstar) {
    return 'darkstar';
  } else if (isDescendent) {
    return 'descendent';
  } else {
    return 'primordial';
  }
}
