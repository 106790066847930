var TIMEOUT_MAX = 2147483647; // 2^31-1

export function setLongTimeout(callback: () => void, ms: number) {
  return new TimeoutClass(callback, ms);
}

export function clearLongTimeout(timer: TimeoutClass) {
  if (timer) timer.close();
}

class TimeoutClass {
  constructor(callback: () => void, ms: number) {
    this.callback = callback;
    this.after = ms;

    this.start();
  }

  callback;
  after;
  timeout: number | undefined;

  start() {
    if (this.after <= TIMEOUT_MAX) {
      this.timeout = window.setTimeout(this.callback, this.after);
    } else {
      var self = this;
      this.timeout = window.setTimeout(function () {
        self.after -= TIMEOUT_MAX;
        self.start();
      }, TIMEOUT_MAX);
    }
  }

  close() {
    window.clearTimeout(this.timeout);
  }
}
