import React from 'react';

import { Button } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';

import { selectedTokenIndex } from 'state/nfts';
import { NFT } from 'state/nfts.types';

export default function Nft(nft: NFT) {
  const setNft = useSetRecoilState(selectedTokenIndex);

  return <Button variant="nav-item" onClick={() => setNft(nft.tokenIndex)}>{`${nft.sides} SIDES ${nft.type}`}</Button>;
}
