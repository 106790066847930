import React from 'react';

import { GridItem, Heading } from '@chakra-ui/react';

export default function Footer() {
  return (
    <>
      <GridItem
        mt="10"
        gridArea="footer"
        borderColor="white"
        gridColumn="2"
        borderTopWidth="2px"
        textAlign="right"
        pr="6">
        <Heading size="md">Jennifer and Kevin Mccoy 2021</Heading>
      </GridItem>
      <GridItem mt="10" gridArea="footer" borderColor="white" borderTopWidth="2px"></GridItem>
    </>
  );
}
