import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
// @ts-ignore
import { UseWalletProvider } from 'use-wallet';

import About from './pages/About/About';
import Home from './pages/Home/Home';
import theme from './theme';

const chainId = parseInt(process.env.REACT_APP_ETHERNET_CHAIN_ID as string);

export const App = () => (
  <RecoilRoot>
    <ChakraProvider theme={theme}>
      <UseWalletProvider chainId={chainId}>
        <BrowserRouter>
          <Switch>
            <Route path="/about" component={About} />
            <Route path="/" component={Home} />
          </Switch>
        </BrowserRouter>
      </UseWalletProvider>
    </ChakraProvider>
  </RecoilRoot>
);
