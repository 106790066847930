import React, { useRef, useEffect, useCallback } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import useResizeObserver from '@react-hook/resize-observer';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';

import QuantumToken from 'contracts/QuantumToken';
import useContract from 'hooks/use-contract';
import { events } from 'lib/renderer/quantumLeap';
import { selectedNft } from 'state/nfts';
import { setLongTimeout, clearLongTimeout } from 'utils/timer';

import RebirthModal from './RebirthModal';

const env = process.env;

export default function Renderer() {
  const mount = useRef(null);
  const nft = useRecoilValue(selectedNft);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const quantumToken = useContract(QuantumToken, { address: env.REACT_APP_QUANTUM_ADDRESS });

  useResizeObserver(mount.current, () => {
    events.emit('resize', mount.current);
  });

  const maybeTriggerRebirth = useCallback(async () => {
    if (quantumToken?.address) {
      const canReborn = await quantumToken.canReborn(nft.tokenIndex);

      if (!canReborn) {
        onOpen();
      }
    }
  }, [nft?.tokenIndex, onOpen, quantumToken?.address]);

  useEffect(() => {
    if (mount.current) {
      events.emit('init', mount.current);
    }
  }, [mount.current]);

  useEffect(() => {
    if (nft) {
      events.emit('state', nft.type, { sides: nft.sides });
    }
  }, [nft]);

  useEffect(() => {
    if (nft && nft.type === 'primordial') {
      const deadTime = DateTime.fromSeconds(nft.lifespan);

      if (deadTime < DateTime.now()) {
        maybeTriggerRebirth();
        events.emit('state', 'dead', { sides: nft.sides });
      } else if (deadTime.diffNow('days').days < 30) {
        events.emit('state', 'transition', { sides: nft.sides });
      }
    }
  }, [maybeTriggerRebirth, nft]);

  useEffect(() => {
    if (nft && nft.type === 'primordial') {
      const deadTime = DateTime.fromSeconds(nft.lifespan);

      if (deadTime.diffNow('days').days > 30) {
        const scheduleEvent = setLongTimeout(
          () => events.emit('state', 'transition', { sides: nft.sides }),
          deadTime.diffNow('milliseconds').milliseconds
        );

        return () => clearLongTimeout(scheduleEvent);
      }
    }
  }, [nft]);

  useEffect(() => {
    if (nft && nft.type === 'primordial') {
      const deadTime = DateTime.fromSeconds(nft.lifespan);

      if (deadTime > DateTime.now()) {
        const scheduleEvent = setLongTimeout(() => {
          events.emit('state', 'dead', { sides: nft.sides });
          maybeTriggerRebirth();
        }, deadTime.diffNow('milliseconds').milliseconds);

        return () => clearLongTimeout(scheduleEvent);
      }
    }
  }, [maybeTriggerRebirth, nft]);

  const handlerDoubleClick = useCallback(async () => {
    if (!document.fullscreenElement) {
      mount.current.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }, []);

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          display: 'flex',
        }}
        ref={mount}
        onDoubleClick={handlerDoubleClick}>
        <canvas id="webgl-display"></canvas>
      </div>
      <RebirthModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
