import React, { useRef, useMemo, ReactNode } from 'react';

import { HamburgerIcon } from '@chakra-ui/icons';
import {
  GridItem,
  IconButton,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useDisclosure,
  Heading,
  LinkOverlay,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useWallet } from 'use-wallet';

import { useQuantumLeapNfts } from 'hooks/use-quantum-leap-nfts';

import ConnectButton from './Sidebar/ConnectButton';
import Nfts from './Sidebar/Nfts';

export default function Sidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  const wallet = useWallet();
  const { loading } = useQuantumLeapNfts();
  const connected = useMemo(() => wallet.status === 'connected', [wallet.status]);

  const navButtons = (
    <>
      <Button as={RouterLink} to="/about" my="1" variant="ghost">
        About
      </Button>
      <Button as={LinkOverlay} href="mailto:info@mccoyspace.com" my="1" variant="ghost">
        Inquire
      </Button>
      <ConnectButton />
    </>
  );

  const rightSidebar: ReactNode =
    !connected || loading ? null : (
      <>
        <GridItem
          display={{ base: 'none', lg: 'flex' }}
          gridArea="rightbar-header"
          width="210px"
          mx="6"
          pb={2}
          alignItems="flex-end"
          borderBottomWidth="2px"
          borderColor="white">
          <Heading size="md">Available NFTs</Heading>
        </GridItem>
        <GridItem
          gridArea="rightbar"
          mx="6"
          pt="4"
          flexDirection="column"
          alignItems="flex-start"
          display={{ base: 'none', lg: 'flex' }}
          borderColor="white">
          <Nfts />
        </GridItem>
      </>
    );

  const drawer = (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {connected && !loading ? (
          <>
            <DrawerHeader borderBottomWidth="2px">Available NFTs</DrawerHeader>
            <DrawerBody display="flex" flexDirection="column" alignItems="flex-start">
              <Nfts />
            </DrawerBody>
          </>
        ) : (
          <DrawerBody />
        )}
        <DrawerFooter flexDirection="column" alignItems="flex-start" borderTopWidth="2px">
          {navButtons}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );

  return (
    <>
      <GridItem
        gridArea="header-leftbar"
        borderBottomWidth="2px"
        borderRightWidth="2px"
        justifyContent="center"
        alignItems="center"
        display="flex"
        p={2}
        borderColor="white">
        <IconButton
          aria-label="Open Drawer"
          ref={btnRef}
          onClick={onOpen}
          icon={<HamburgerIcon w={6} h={6} />}
          variant="ghost"
          display={{ lg: 'none' }}
        />
      </GridItem>
      <GridItem
        p={4}
        display={{ base: 'none', lg: 'flex' }}
        gridArea="leftbar"
        alignContent="flex-end"
        textAlign="right"
        flexDirection="column"
        borderRightWidth="2px"
        alignItems="flex-end"
        borderColor="white">
        {navButtons}
      </GridItem>

      {rightSidebar}
      {drawer}
    </>
  );
}
