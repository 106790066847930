import { atom, selector } from 'recoil';

import { NFT, QuantumLeapData } from './nfts.types';

export const quantumLeapNft = atom<QuantumLeapData>({
  key: 'nfts',
  default: { loading: true, nfts: null },
});

export const selectedTokenIndex = atom<number | null>({
  key: 'selectedTokenIndex',
  default: null,
});

export const selectedNft = selector<NFT | undefined>({
  key: 'selectedNFT',
  get: ({ get }) => {
    const selectedIndex = get(selectedTokenIndex);
    const nfts = get(quantumLeapNft).nfts;
    return nfts?.find((nft) => nft.tokenIndex === selectedIndex);
  },
});

export default {
  atoms: { quantumLeapNft, selectedTokenIndex },
  selectors: { selectedNft },
};
