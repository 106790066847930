import React, { useMemo } from 'react';

import { useWallet } from 'use-wallet';

import Layout from '../../layout/Layout';

import Introduction from './components/Introduction';
import Renderer from './components/Renderer';

export default function Home() {
  const wallet = useWallet();
  const connected = useMemo(() => wallet.status === 'connected', [wallet.status]);

  return <Layout>{connected ? <Renderer /> : <Introduction />}</Layout>;
}
