import React from 'react';
import { useCallback } from 'react';

import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, ModalHeader, Text, Button } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { useWallet } from 'use-wallet';

import QuantumToken from 'contracts/QuantumToken';
import useContract from 'hooks/use-contract';
import { useQuantumLeapNfts } from 'hooks/use-quantum-leap-nfts';
import { selectedNft } from 'state/nfts';

const env = process.env;

export default function RebirthModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const wallet = useWallet();
  const quantumToken = useContract(QuantumToken, { address: env.REACT_APP_QUANTUM_ADDRESS as string });
  const nft = useRecoilValue(selectedNft);
  const { refetch } = useQuantumLeapNfts();

  const onRebirth = useCallback(async () => {
    if (nft) {
      await quantumToken.rebornAndDescendent(nft?.tokenIndex, { from: wallet.account });
      refetch();
      onClose();
    }
  }, [quantumToken?.address, wallet.account, nft, refetch, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Text>Do you want to rebirth the token?</Text>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} fontSize="md" onClick={onRebirth}>
            Rebirth
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
