import { extendTheme, ThemeConfig } from '@chakra-ui/react';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

export default extendTheme({
  config,
  borders: {
    '2px': '2px solid white',
  },
  components: {
    Button: {
      baseStyle: {
        fontFamily: 'Bebas Neue',
        fontWeight: 'normal',
      },
      sizes: {
        md: {
          fontSize: 'xl',
        },
      },
      variants: {
        solid: {
          color: '#000',
          backgroundColor: '#fff',
          _hover: {
            backgroundColor: 'whiteAlpha.700',
          },
        },
        'nav-item': {
          p: '1',
          my: '0.5',
          borderRadius: '0',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: 'transparent',
          justifyContent: 'flex-start',
          height: 'auto',
          width: '100%',
          _active: { borderColor: 'rgba(255,255,255,0.16)', bg: 'rgba(255,255,255,0.1)' },
          _focus: null,
          _hover: null,
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: 'Bebas Neue',
        fontWeight: 'normal',
      },
    },
  },
  fonts: {
    heading: 'Bebas Neue',
  },
});
