import React from 'react';

import { GridItem, Heading } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export default function Header() {
  return (
    <GridItem
      gridArea="header"
      p={4}
      mr="14"
      alignItems="flex-end"
      display="flex"
      borderBottomWidth="2px"
      borderColor="white">
      <Heading as={RouterLink} to="/">
        Quantum Leap
      </Heading>
    </GridItem>
  );
}
