import React from 'react';

import { Box, Text, Image, Heading } from '@chakra-ui/react';

import thirtyThree from '../../assets/33.jpeg';
import Layout from '../../layout/Layout';

export default function About() {
  return (
    <Layout>
      <Box maxH="100%" maxW="100%" overflow="auto">
        <Heading size="lg">The Quantum Leap NFTs:</Heading>

        <Text>
          On June 24, 2021, the first Quantum Leap token minted on June 22, 2021 was sold at Phillips Auction House. At
          present, seven more tokens remain to be minted and distributed.
        </Text>
        <br />
        <Heading size="md">Credits:</Heading>

        <Text>
          Quantum Leap was created by Jennifer and Kevin McCoy with production support from Monegraph and David Stein.
        </Text>
        <br />
        <Heading size="md">Background:</Heading>

        <Text>
          Quantum Leap is a generative NFT that was inspired by Kevin McCoy's earlier work, Quantum. The Quantum NFT was
          created on May 2, 2014. It was the first digital artwork to be registered on a blockchain and the first fully
          specific, fully transferable NFT.
        </Text>
        <br />
        <Image src={thirtyThree} objectFit="contain" boxSize="250px" />
        <br />
        <Text>
          After Quantum was created, Kevin McCoy's original concept of the digital art NFT was debuted to the public at
          the Seven on Seven Rhizome at the New Museum in New York on May 4th, 2014.
        </Text>
        <br />
        <Text>
          McCoy’s presentation included research begun in 2013 about the new cryptocurrency technology and the use of
          blockchains to verify digital artworks, making digital art functionally unique with irreversible and
          transferable ownership. In the context of the New Museum's event, presented with technologist Anil Dash,
          demonstrations included the minting of the NFT Primordial Loop and the public sale of Cars, demonstrating the
          transfer of its ownership on-chain.
        </Text>
        <br />
        <Text>
          On June 10, 2021 Quantum (subsequently reminted on the Etherium blockchain) was sold as part of the Sotheby's
          "Natively Digital" curation for a $1.47 million dollar hammer price.
        </Text>
      </Box>
    </Layout>
  );
}
