import React from 'react';

import { Text } from '@chakra-ui/react';

import { useQuantumLeapNfts } from 'hooks/use-quantum-leap-nfts';

import Nft from './Nft';
export default function Nfts() {
  const { nfts, loading } = useQuantumLeapNfts();
  const owner = nfts?.length;
  const nftsButton = nfts?.map((nft) => <Nft key={nft.tokenIndex} {...nft} />);

  if (loading) {
    return null;
  }

  return owner ? <>{nftsButton}</> : <Text>We were unable to find the NFT in your wallet</Text>;
}
