import React from 'react';

import { Text, Box, Image, SimpleGrid, Heading } from '@chakra-ui/react';

import twenty from '../../../assets/20.png';
import four from '../../../assets/4.png';
import nine from '../../../assets/9 Dark.png';

export default function Introduction() {
  return (
    <Box maxH="100%" maxW="100%" overflow="auto">
      <SimpleGrid columns={[2, 3]} spacing={4} style={{ paddingRight: 20 }}>
        <Box>
          <Image src={four} objectFit="contain" />
        </Box>

        <Box>
          <Image src={nine} objectFit="contain" />
        </Box>

        <Box>
          <Image src={twenty} objectFit="contain" />
        </Box>
      </SimpleGrid>
      <Text>Quantum Leap. Jennifer and Kevin McCoy, 2021</Text>
      <br />
      <Heading size="lg">ARTISTS STATEMENT:</Heading>
      <Text>
        As media artists, we have always been interested in art that creates effects in real time in unexpected ways.
        Inspired by artists like John Cage, our work strives to create an initial set of structures that then play out
        over a period of time. Quantum Leap, with its three year cycle of evolution, is the longest term artwork we have
        yet made. With innovations in NFTs and the social dynamics around them, Quantum Leap as it evolves and
        multiplies extends the terrain of the work of art into something more like an eco-system.
      </Text>
      <br />
      <Text>
        Quantum Leap is a generative code-based NFT project inspired by McCoy's 2014 work Quantum, the first artwork
        tokenized on a blockchain. The word 'quantum" alludes to the attempt in theoretical physics to develop a theory
        of the universe. This approach to physics generally also strives to answer questions about the first phases of
        the universe. This generative, iterative process is emulated in the structure of Quantum Leap which evolves to
        become more complex, changing states, and spinning off new versions of itself.
      </Text>
      <br />
      <Text>
        For this project, several levels of code-based systems interact. Visually, each token of theQuantum Leap series
        produces a mandala that changes and evolves inspired by the life cycle colors of the stars (which shift colors
        as they age) and becomes more circular as the code-generated images mature.
      </Text>
      <br />
      <Text>
        The code-execution environment that drives the animation is found both on a dedicated website and eventually, as
        a downloadable stand alone application. This website allows collectors who have Quantum Leap tokens to visit the
        site, connect their wallets (or download the .exe) and see the artwork that corresponds to their token. The
        software will read the specific tokens and derive operational parameters from on-chain data belonging to each
        token.
      </Text>
      <br />
      <Heading size="md">TOKENOMICS-</Heading>
      <Text>
        The tokens ofQuantum Leap can do three things. They can generate DESCENDENT tokens, they can EVOLVE, and they
        can go DARK after EVOLUTION. Each of these actions produces specific animated states.
      </Text>
      <br />
      <Heading size="md">PRIMORDIALS– Limited Set of 8</Heading>
      <Text>
        The code for Quantum Leap contains a function which over time slowly complicates the visual pattern of the
        token. When the Primordial Star increases its side count, the previous side count animation will begin to decay
        and a new Primordial Star token will generate. The timing of this event will vary depending on on-chain
        parameters.
      </Text>
      <br />
      <Text>
        Initially the eight ("Primordial Stars") have set unique starting values – including their initial number of
        sides (3, 4, 5, 6, 7, 8, 9, and 10) and unique color palettes. These initial tokens progress and produce
        offspring which the original token owner will also own (see below).
      </Text>
      <br />
      <Text>
        Over time the side count of thePrimordial Stars tokens will then evolve from the initial side count to twenty
        sides.
      </Text>
      <br />
      <Heading size="md">DESCENDENT STARS–</Heading>
      <br />
      <Text>
        The NFT tokens will contain dynamics which will allow for the creation of newly derived tokens with their own
        parameters that will drive unique visual patterns in the rendering software. Each of the eight Primordial Stars
        will spawn between one and seven offspring that will be generated by random mutations. The entire spawning
        period will end in three years. New born DESCENDENT tokens will not evolve nor die. The total number of tokens
        in the project including the Primordials and DESCENDENTS will equal 64 tokens.
      </Text>
      <br />
      <Heading size="md">DARKSTARS-</Heading>
      <Text>
        The code contains a decay function which, one initiated, over time desaturates the image to a grayscale
        animating mandala. The conditions to initiate 'death' are random and must happen at least once before the 3 year
        limit. DARKSTARS stars will not be part of the total 64 token limit.
      </Text>
      <br />
      <Heading size="md">EVOLVED PRIMORDIALS-</Heading>
      <br />
      <Text>
        After a token with X side count becomes a DARKSTAR, it evolves into a token with one additional side (example
        from 3 to 4 sides).
      </Text>
      <br />
      <Heading size="md">EXAMPLE CASE:</Heading>
      <br />
      <Text>Collector buys Primordial Star 1 which begins life as a three sided animation.</Text>
      <br />
      <Text>
        - One week later, the collector connects wallet and sees that she now possesses a grayscale 3 sided token (a
        DARKSTAR) and a 4 sided piece (the EVOLVED PRIMORDIAL token), both animating.
      </Text>
      <br />
      <Text>
        - Six weeks later, the collector sees that her 4 sided animation is spawning with one open side. After this, she
        has two 4 sided tokens with closed sides, both animating a EVOLVED PRIMORDIAL and an descendent STAR, which will
        not progress.
      </Text>
      <br />
      <Text>
        - One year after that, the collector sees that she has an 8 sided token (the EVOLVED PRIMORDIAL), a 7 sided
        DARKSTAR, a 6 SIDED DARKSTAR, a 5 SIDED DARKSTAR, a 4 side DARKSTAR, and a 3 sided DARKSTAR.. She also has the
        DESCENDENT star.
      </Text>
      <br />
    </Box>
  );
}
