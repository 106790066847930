import React, { useMemo } from 'react';

import { Button } from '@chakra-ui/react';
import { useWallet } from 'use-wallet';

export default function ConnectButton() {
  const wallet = useWallet();
  const connected = useMemo(() => wallet.status === 'connected', [wallet.status]);

  const { text, onClick } = useMemo(() => {
    return {
      text: connected ? 'Disconnect' : 'Connect to Metamask',
      onClick: connected ? () => wallet.reset() : () => wallet.connect('injected'),
    };
  }, [connected, wallet]);

  return (
    <Button my="1" variant="solid" onClick={onClick}>
      {text}
    </Button>
  );
}
