import { useMemo, useState } from 'react';

import { times } from 'lodash';
import { useRecoilState } from 'recoil';
import { useWallet } from 'use-wallet';

import QuantumToken from 'contracts/QuantumToken';
import useContract from 'hooks/use-contract';
import { quantumLeapNft } from 'state/nfts';
import { getNftType } from 'utils/nft';

import { QuantumLeapData } from '../state/nfts.types';

const env = process.env;

export function useQuantumLeapNfts() {
  const wallet = useWallet();
  const quantumToken = useContract(QuantumToken, { address: env.REACT_APP_QUANTUM_ADDRESS as string });
  const [nfts, setNfts] = useRecoilState<QuantumLeapData>(quantumLeapNft);
  const [fetching, setFetching] = useState<Boolean>(!Array.isArray(nfts));

  useMemo(async () => {
    if (quantumToken?.address && fetching) {
      const b = await quantumToken.balanceOf(wallet.account);
      const balance = b.toNumber();

      const result = times(balance, async (value) => {
        const tokenIndex = await quantumToken.tokenOfOwnerByIndex(wallet.account, value);
        const tokenNumber: number = tokenIndex.toNumber();

        const [sides, isDarkstar, isDescendent, lifespan] = await Promise.all<any, boolean, boolean, any>([
          quantumToken._tokenSides(tokenNumber),
          quantumToken._tokenDark(tokenNumber),
          quantumToken._tokenDescendent(tokenNumber),
          quantumToken._tokenLifespan(tokenNumber),
        ]);

        return {
          tokenIndex: tokenNumber,
          sides: sides.toNumber(),
          isDarkstar,
          isDescendent,
          lifespan: lifespan.toNumber(),
          type: getNftType(isDarkstar, isDescendent),
        };
      });
      setFetching(false);
      setNfts({ loading: false, nfts: await Promise.all(result) });
    }
  }, [wallet.account, quantumToken?.address, setNfts, fetching]);

  return { ...nfts, refetch: () => setFetching(true) };
}
